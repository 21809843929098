import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w04ER02Englisch = () => (
  <Layout>
    <SEO title="w04ER02Englisch" />

    <h1 id="ueberschrift">Einfach (mal) Reden</h1>
    <h2>Englisch &emsp;  ©&nbsp;1989</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w04ER02Englisch&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Meist ist es einfacher etwas zu lernen, wenn es mit anderen gemeinsam geübt werden kann.
        Aus diesem Grund bietet dieser Kurs die Möglichkeit
        bei einem gemütlichen Beisammensein das Gewünschte zu trainieren. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten in englischer Sprache die Zeit genießen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        <b>Muttersprachler sind herzlich willkommen!</b><br />
        <b>Native speakers are very welcome!</b>
      </p>
      
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        Voraussetzungen: + Grundkenntnisse der englischen Sprache +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w04ER02Englisch
